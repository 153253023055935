import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n

  .use(initReactI18next)

  .init({
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          "Login": "Login",
          "Welcome": "Welcome",
          'Landing1': "You’re about to embark on the journey to earn points and redeem exciting rewards!",
          "Next": "Next",
          'Get Started': 'Get Started',
          'Landing2': 'Upload receipt by purchasing any GoodMorning products.',
          'Upload Receipt': 'Upload Receipt',
          'Collect Points': 'Collect Points',
          'Landing3': 'Collect more points to unlock the tier and redeem exciting rewards!',
          'Log In': 'Log In',
          'LoginText': 'To log in, please provide your phone number:',
          'Enter Number': 'Enter your phone number',
          'OTP Verification': 'OTP Verification',
          'Please enter the verification code': 'Please enter the verification code',
          'Verify': 'Verify',
          'Resend Code': 'Resend Code',
          'Resend Code in': 'Resend Code in',
          'Your number has been verified!': 'Your number has been verified!',
          'Register': 'Register',
          'Registration': 'Registration',
          'CompleteForm': 'Please complete the form to proceed',
          'Full Name': 'Full Name',
          'Date of Birth': 'Date of Birth',
          'Email': 'Email',
          'I Agree': 'I agree to the GoodMorning Global’s',
          'and the': 'and the',
          'I Authorize': 'I authorize GoodMorning Global to share my personal data* with selected third parties to provide value-added services as a G-Connect member.',
          'Submit': 'Submit',
          'HaveAccount': 'Already have an account?',
          'Login Here': 'Login Here',
          'Success': 'Success!',
          'SuccessRegister': 'Your account has been successfully registered.',
          'Done': 'Done',
          'Unsuccessful': 'Unsuccessful',
          'AlreadyRegister': 'This email is already registered. Please use a different email.',
          'Home': 'Home',
          'toGold': 'more to GOLD',
          'toSilver': 'more to SILVER',
          'PointShop': 'Point Shop',
          'ReferEarn': 'Refer & Earn',
          'Badges': 'Badges',
          'My Submissions': 'My Submissions',
          'January': 'January',
          'February': 'February',
          'March': 'March',
          'April': 'April',
          'May': 'May',
          'June': 'June',
          'July': 'July',
          'August': 'August',
          'September': 'September',
          'October': 'October',
          'November': 'November',
          'December': 'December',
          'Approved': 'Approved',
          'Pending': "Pending",
          "Rejected": "Rejected",
          'Upload New': 'Upload New',
          'TermsApply': '*Terms & Conditions apply',
          'Popular Rewards': 'Popular Rewards',
          'View All': 'View All',
          'eWallet Reload Pin': 'eWallet Reload Pin',
          'pts': 'pts',
          'Rewards': 'Rewards',
          'Get 2X Points for First 3 Receipts': 'Get 2X Points for First 3 Receipts',
          'History': 'History',
          'Profile': 'Profile',
          'My Points': 'My Points',
          'My Rewards': 'My Rewards',
          'All': "All",
          'Item': "Item",
          'View Cart': 'View Cart',
          'Voucher': "Voucher",
          'ProductInfo': 'Product Information',
          'Cart': 'Cart',
          'Total': 'Total',
          'Checkout': 'Proceed to Checkout',
          'Redeem Reward': 'Redeem Reward?',
          'Redeem': 'Redeem',
          'ConfirmRedeem': "Are you sure you want to redeem this reward? Your points will be deducted once details confirmation is done.",
          "Cancel": 'Cancel',
          'Confirm': 'Confirm',
          'SuccessRedeem': 'You have successfully completed your redemption.',
          'GoRewards': 'Go to Rewards',
          exp: 'exp.',
          'copied': 'Copied to clipboard',
          'Coming Soon': 'Coming Soon',
          workingOn: "We're working on something amazing that we can't wait to share with you.",
          'Check Back': 'Check back soon for updates!',
          'Close': 'Close',
          'UploadPhoto': 'Upload a photo of your receipt',
          'AddedCart': 'Added to cart successfully!',
          'Receipt Amount': 'Receipt Amount',
          'EnterAmount': 'Enter your amount',
          'Receipt Date': 'Receipt Date',
          'DD/MM/YYYY': 'DD/MM/YYYY',
          'Receipt Number': 'Receipt Number',
          'EnterNumber': 'Enter receipt number',
          'Receipt Sample': 'Receipt Sample',
          'Sample': 'Sample of physical receipt:',
          'Store Name': 'Store Name',
          'InvoiceNo': 'Receipt / Invoice No.',
          'Product Name': 'Product Name',
          ESample: 'Sample of e-commerce receipt:',
          SuccessUpload: 'Your receipt has been successfully uploaded and will be validated within 5 working days.',
          'Sure?': 'Are you sure?',
          'DeleteSure': 'Are you sure you want to delete this item?',
          'Delete': 'Delete',
          'Points Earned': 'Points Earned',
          'Points': 'Points',
          'Personal Details': 'Personal Details',
          'Delivery Details': 'Delivery Details',
          'Refer a Friend': 'Refer a Friend',
          'Tiering': 'Tiering',
          'Language': 'Language',
          'Help': 'Help',
          'Preferences': 'Preferences',
          'Logout': 'Logout',
          'Email Address': 'Email Address',
          'Phone Number': 'Phone Number',
          'Save': 'Save',
          'DetailsUpdate': 'Your details have been successfully updated.',
          'EditAddress': 'Edit Delivery Address',
          'AddAddress': 'Add New Address',
          'DeliveryAddress': 'Enter your address for delivery.',
          'MobileNumber': 'Mobile Number',
          'Name': 'Name',
          'Line1': 'Address line 1',
          'EnterLine1': 'Enter your address (line 1)',
          'Line2': 'Address line 2',
          'EnterLine2': 'Enter your address (line 2)',
          'City': 'City',
          'ZIP / Postcode': 'ZIP / Postcode',
          'Postcode': 'Postcode',
          'AddressSaved': 'Address has been successfully saved.',
          'Tiering Benefits': 'Tiering Benefits',
          'Tiering Points Required': 'Tiering Points Required',
          'Privileges': 'Privileges',
          'Welcome Reward': 'Welcome Reward',
          'Referral Rewards': 'Referral Rewards',
          'Yearly': 'Yearly',
          'Tiering Expiring': 'Tiering Expiring',
          'Birthday Rewards': 'Birthday Rewards',
          'FAQ': 'FAQ',
          'T&C': 'T&C',
          'Terms & Conditions': 'Terms & Conditions',
          'Terms and Conditions': 'Terms and Conditions',
          'Privacy Policy': 'Privacy Policy',
          'Campaign Related': 'Campaign Related',
          'General': 'General',
          'I would like to receive communication from GoodMorning Global about nutritional information, member benefits, latest products updates, member offers and other information. Contact GoodMorning Global for any questions. For further reading, please read': 'I would like to receive communication from GoodMorning Global about nutritional information, member benefits, latest products updates, member offers and other information. Contact GoodMorning Global for any questions. For further reading, please read no',
          'Direct Mail': 'Direct Mail',
          'SMS': 'SMS',
          'Phone Call': 'Phone Call',
          'Call': 'Call',
          'I would like to receive communication on product updates, promotions & loyalty program from GoodMorning Global. You can unsubscribe at any time by updating this page.': 'I would like to receive communication on product updates, promotions & loyalty program from GoodMorning Global. You can unsubscribe at any time by updating this page.',
          'SPEND AND WIN': 'SPEND AND WIN',
          'Prizes worth over': 'Prizes worth over',
          'Prizes': 'Prizes',
          'How To Win': 'How To Win',
          'GRAND PRIZE': 'GRAND PRIZE',
          '10 x Cash RM2,888': '10 x Cash RM2,888',
          'CONSOLATION': 'CONSOLATION',
          'Bi-Weekly Prize': 'Bi-Weekly Prize',
          'Go to your shopping cart and select your purchases to "Check Out".': 'Go to your shopping cart and select your purchases to "Check Out".',
          'At the Checkout page.': 'At the Checkout page.',
          'Enter your promotion code on the Gift Card in the "Enter Voucher Code" field and tap on the "Apply" button to redeem your Gift Card.': 'Enter your promotion code on the Gift Card in the "Enter Voucher Code" field and tap on the "Apply" button to redeem your Gift Card.',
          'Once applied, continue to check out by tapping on the "Proceed to Checkout" button for the website or "Place Order" button to continue the payment and complete the purchase.': 'Once applied, continue to check out by tapping on the "Proceed to Checkout" button for the website or "Place Order" button to continue the payment and complete the purchase.',
          'Add To Cart': 'Add To Cart',
          'Your Cart is Currently Empty': 'Your Cart is Currently Empty',
          'BeforeProceed': 'Before proceeding to the checkout page, ensure you have added everything you need in this cart.',
          'Return to Point Shop': 'Return to Point Shop',
          'Refer & Earn': 'Refer & Earn',
          'Refer friends, get EXTRA points!': 'Refer friends, get EXTRA points!',
          'Points History': 'Points History',
          "How to Redeem Your Touch 'n Go Reload PIN?": "How to Redeem Your Touch 'n Go Reload PIN?",
          "Open the Touch 'n Go eWallet Reload PIN App": "Open the Touch 'n Go eWallet Reload PIN App",
          'Click "Reload"': 'Click "Reload"',
          'Click "eWallet Balance"': 'Click "eWallet Balance"',
          'Click on "TNG eWallet Reload PIN" below of the page': 'Click on "TNG eWallet Reload PIN" below of the page',
          'Key in the TNG eWallet Reload PIN and click "Reload Now"': 'Key in the TNG eWallet Reload PIN and click "Reload Now"',
          "Successfully redeem Touch 'n Go eWallet voucher": "Successfully redeem Touch 'n Go eWallet voucher",
          "Only ONE (1) Reload PIN Reward can be used per reload": "Only ONE (1) Reload PIN Reward can be used per reload",
          'To utilize the Reload Pin, log in on the microsite. At "Rewards", tap on the "View Now" button under your rewards. Then, tap on the copy icon next to the Voucher Code of the respective rewards and follow the steps provided.': 'To utilize the Reload Pin, log in on the microsite. At "Rewards", tap on the "View Now" button under your rewards. Then, tap on the copy icon next to the Voucher Code of the respective rewards and follow the steps provided.',
          'Used Reload Pin Reward are non-reversible. Once used, the amount is not exchangeable nor refundable for credits, cash or such replacement in part or in full.': 'Used Reload Pin Reward are non-reversible. Once used, the amount is not exchangeable nor refundable for credits, cash or such replacement in part or in full.',
          'TNG Digital will not in any way contact users for any personal information or account credentials for this Campaign.': 'TNG Digital will not in any way contact users for any personal information or account credentials for this Campaign.',
          'Reload Pin will be expired on': 'Reload Pin will be expired on',
          'Exclusive Discount for': 'Exclusive Discount for',
          'Redemption rewards ONLY': 'Redemption rewards ONLY',
          'Your details have been successfully updated.': 'Your details have been successfully updated.',
          'It seems like the one-time PIN you entered is incorrect. Please try again with the correct code.': 'It seems like the one-time PIN you entered is incorrect. Please try again with the correct code.',
          'NOTE: This submission is entitled to 2X Points': "NOTE: This submission is entitled to 2X Points",
          'Points Spent': 'Points Spent',
          'Get 2X Points for First 3 Purchases of the Month': 'Get 2X Points for First 3 Purchases of the Month',
          'Items': 'Items',
          'Subtotal': 'Subtotal',
          'Please select at least one valid item to proceed.': 'Please select at least one valid item to proceed.',
          'Discount': 'Discount',
          'Are you sure you want to delete this address info?': 'Are you sure you want to delete this address info?',
          'Yes': 'Yes',
          'No': 'No',
          'Expires On': 'Expires On',
          'Enter your name': 'Enter your name',
          'Enter your mobile number': 'Enter your mobile number',
          'Copy': 'Copy',
          'above': 'above'
        }
      },
      bm: {
        translation: {
          "Login": "Log Masuk",
          "Welcome": "Selamat Datang",
          'Landing1': "Anda akan mula meraih mata dan tebus ganjaran yang menarik!",
          "Next": "Seterus",
          'Get Started': 'Mula',
          'Landing2': 'Upload receipt by purchasing any GoodMorning products.',
          'Upload Receipt': 'Muat Naik Resit',
          'Collect Points': 'Kumpulkan Mata',
          'Landing3': 'Kumpulkan lebih banyak mata untuk membuka lapisan dan tebus ganjaran yang menarik!',
          'Log In': 'Log Masuk',
          'LoginText': 'Untuk log masuk, sila berikan nombor telefon anda:',
          'Enter Number': 'Masukkan nombor telefon anda',
          'OTP Verification': 'Pengesahan OTP',
          'Please enter the verification code': 'Sila masukkan kod pengesahan yang telah dihantar',
          'Verify': 'Sahkan',
          'Resend Code': 'Hantar semula kod',
          'Resend Code in': ' Hantar semula kod dalam',
          'Your number has been verified!': 'Nombor anda telah disahkan!',
          'Register': 'Daftar',
          'Registration': 'Pendaftaran',
          'CompleteForm': 'Sila lengkapkan borang untuk meneruskan',
          'Full Name': 'Nama Penuh',
          'Date of Birth': 'Tarikh Lahir',
          'Email': 'Emel',
          'I Agree': 'Saya bersetuju dengan Terma dan Syarat serta Polisi Privasi GoodMorning Global.',
          'and the': 'serta',
          'I Authorize': 'Saya memberi kebenaran kepada GoodMorning Global untuk berkongsi data peribadi saya* dengan pihak ketiga yang dipilih untuk menyediakan perkhidmatan bernilai tambah sebagai ahli G-Connect.',
          'Submit': 'Hantar',
          'HaveAccount': 'Sudah memiliki akaun?',
          'Login Here': 'Log Masuk Di Sini',
          'Success': 'Berjaya!',
          'SuccessRegister': 'Akaun anda berjaya didaftarkan.',
          'Done': 'Selesai',
          'Unsuccessful': 'Tidak Berjaya',
          'AlreadyRegister': 'Emel ini sudah didaftarkan. Sila gunakan emel yang berbeza.',
          'Home': 'Laman Utama',
          'toGold': 'mata lagi untuk EMAS',
          'toSilver': 'mata lagi untuk PERAK',
          'PointShop': 'Kedai Mata',
          'ReferEarn': 'Rujuk & Tebus',
          'Badges': 'Lencana',
          'My Submissions': 'Hantaran Saya',
          'January': 'Januari',
          'February': 'Februari',
          'March': 'Mac',
          'April': 'April',
          'May': 'Mei',
          'June': 'Jun',
          'July': 'Julai',
          'August': 'Ogos',
          'September': 'September',
          'October': 'Oktober',
          'November': 'November',
          'December': 'Disember',
          'Approved': 'Diluluskan',
          'Pending': "Belum Diluluskan",
          'Processing': "Dalam Proses",
          "Rejected": "Ditolak",
          'Upload New': 'Muat Naik Baru',
          'TermsApply': '*Terma & Syarat terpakai"',
          'Popular Rewards': 'Ganjaran Popular',
          'View All': 'Lihat Semua',
          'eWallet Reload Pin': 'Pin Topup eWallet',
          'pts': 'mata',
          'Rewards': 'Ganjaran',
          'History': 'Sejarah',
          'Profile': 'Profil',
          'My Points': 'Mata Saya',
          'My Rewards': 'Ganjaran Saya',
          'All': "Semua",
          'Item': "Item",
          'View Cart': 'Lihat Troli',
          'Voucher': "Baucer",
          'ProductInfo': 'Maklumat Produk',
          'Cart': 'Troli',
          'Total': 'Jumlah',
          'Checkout': 'Teruskan ke Checkout',
          'Redeem Reward': "Tebus Ganjaran?",
          'Redeem': 'Tebus',
          'ConfirmRedeem': "Adakah anda pasti ingin tebus ganjaran ini? Mata anda akan dikurangkan setelah pengesahan butiran selesai.",
          "Cancel": 'Batal',
          'Confirm': 'Sahkan',
          'SuccessRedeem': 'Anda telah berjaya menyelesaikan penebusan anda.',
          'GoRewards': 'Pergi ke Ganjaran',
          exp: 'tamat pada',
          'copied': 'Disalin ke papan keratan',
          'Coming Soon': 'Segera Datang',
          workingOn: "Kami sedang bekerja pada sesuatu yang menakjubkan yang kami tidak sabar untuk berkongsi dengan anda.",
          'Check Back': 'Sila semak kembali untuk kemas kini!',
          'Close': 'Tutup',
          'UploadPhoto': 'Muat Naik gambar resit anda',
          'AddedCart': 'Berjaya ditambah ke troli!',
          'Receipt Amount': 'Jumlah Resit',
          'EnterAmount': 'Masukkan jumlah',
          'Receipt Date': 'Tarikh Resit',
          'DD/MM/YYYY': 'DD/MM/YYYY',
          'Receipt Number': 'Nombor Resit',
          'EnterNumber': 'Masukkan nombor resit',
          'Receipt Sample': 'Sampel Resit',
          'Sample': 'Contoh resit fizikal:',
          'Store Name': 'Nama Kedai',
          'InvoiceNo': 'Nombor Resit / Invois',
          'Product Name': 'Nama Produk',
          ESample: 'Contoh resit e-dagang:',
          SuccessUpload: 'Resit anda telah berjaya dimuat naik dan akan disahkan dalam masa 5 hari bekerja.',
          'Sure?': 'Anda pasti?',
          'DeleteSure': 'Adakah anda pasti ingin memadamkan item ini?',
          'Delete': 'Padam',
          'Points Earned': 'Mata Diperolehi',
          'Points': 'Mata',
          'Personal Details': 'Maklumat Peribadi',
          'Get 2X Points for First 3 Receipts': 'Dapatkan 2X Mata untuk Resit Pertama 3 kali',
          'Delivery Details': 'Maklumat Penghantaran',
          'Refer a Friend': 'Rujuk Kepada Rakan',
          'Tiering': 'Pengkelasan',
          'Language': 'Bahasa',
          'Help': 'Bantuan',
          'Preferences': 'Keutamaan',
          'Logout': 'Log Keluar',
          'Email Address': 'Alamat Emel',
          'Phone Number': 'Nombor Telefon',
          'Save': 'Simpan',
          'DetailsUpdate': 'Maklumat anda telah berjaya dikemaskini.',
          'EditAddress': 'Edit Alamat Penghantaran',
          'AddAddress': 'Tambah Alamat Baru',
          'DeliveryAddress': 'Masukkan alamat penghantaran anda.',
          'MobileNumber': 'Nombor Telefon Bimbit',
          'Name': 'Nama',
          'Line1': 'Alamat Baris 1',
          'EnterLine1': 'Masukkan alamat anda (baris 1)',
          'Line2': 'Alamat Baris 2',
          'EnterLine2': 'Masukkan alamat anda (baris 2)',
          'City': 'Bandar',
          'ZIP / Postcode': 'ZIP / Poskod',
          'Postcode': 'Poskod',
          'AddressSaved': 'Alamat telah berjaya disimpan.',
          'Tiering Benefits': 'Faedah Pengkelasan',
          'Tiering Points Required': 'Mata Pengkelasan Diperlukan:',
          'Privileges': 'Keistimewaan',
          'Welcome Reward': 'Ganjaran Selamat Datang',
          'Referral Rewards': 'Ganjaran Rujukan',
          'Yearly': 'Setiap Tahun',
          'Tiering Expiring': 'Pengkelasan akan tamat',
          'Birthday Rewards': 'Ganjaran Hari Jadi',
          'FAQ': 'Soalan Lazim',
          'Terms & Conditions': 'Terma & Syarat',
          'T&C': 'Terma dan Syarat',
          'Terms and Conditions': 'Terma dan Syarat',
          'Privacy Policy': 'Dasar Privasi',
          'Campaign Related': 'Berkaitan Kempen',
          'General': 'Umum',
          'I would like to receive communication from GoodMorning Global about nutritional information, member benefits, latest products updates, member offers and other information. Contact GoodMorning Global for any questions. For further reading, please read': 'Saya ingin menerima komunikasi dari GoodMorning Global mengenai maklumat pemakanan, faedah ahli, kemas kini produk terkini, tawaran ahli, dan maklumat lain. Hubungi GoodMorning Global untuk sebarang pertanyaan. Untuk bacaan lanjut, sila baca',
          'Direct Mail': 'Mel Direct',
          'SMS': 'SMS',
          'Phone Call': 'Panggilan Telefon',
          'Call': 'Panggilan',
          'I would like to receive communication on product updates, promotions & loyalty program from GoodMorning Global. You can unsubscribe at any time by updating this page.': 'Saya ingin menerima komunikasi mengenai kemas kini produk, promosi & program kesetiaan dari GoodMorning Global. Anda boleh berhenti langganan pada bila-bila masa dengan mengemaskini halaman ini.',
          'SPEND AND WIN': 'BELI DAN MENANG',
          'Prizes worth over': 'Hadiah bernilai lebih dari',
          'Prizes': 'Hadiah',
          'How To Win': 'Cara Menang',
          'GRAND PRIZE': 'HADIAH UTAMA',
          '10 x Cash RM2,888': '10 x Tunai RM2,888',
          'CONSOLATION': 'SAGUHATI',
          'Bi-Weekly Prize': 'Hadiah Setiap Dua Minggu',
          'Go to your shopping cart and select your purchases to "Check Out".': 'Pergi ke troli beli-beli anda dan pilih pembelian anda untuk "Pembayaran".',
          'At the Checkout page.': 'Di halaman Pembayaran.',
          'Enter your promotion code on the Gift Card in the "Enter Voucher Code" field and tap on the "Apply" button to redeem your Gift Card.': 'Masukkan kod promosi anda pada Kad Hadiah di dalam medan "Masukkan Kod Baucer" dan ketuk butang "Apply" untuk menebus Kad Hadiah anda.',
          'Once applied, continue to check out by tapping on the "Proceed to Checkout" button for the website or "Place Order" button to continue the payment and complete the purchase.': 'Setelah diterapkan, teruskan ke halaman pembayaran dengan mengetuk butang "Proceed to Checkout" untuk laman web atau butang "Place Order" untuk aplikasi untuk meneruskan pembayaran dan menyelesaikan pembelian.',
          'Add To Cart': 'Tambah ke troli',
          'Your Cart is Currently Empty': 'Troli anda kini kosong',
          'BeforeProceed': 'Sebelum ke halaman pembayaran, pastikan anda telah menambahkan segala yang anda perlukan di dalam troli ini.',
          'Return to Point Shop': 'Kembali ke Kedai Mata',
          'Refer & Earn': 'Rujuk & Jana',
          'Refer friends, get EXTRA points!': 'Rujuk rakan, dapatkan mata EXTRA!',
          'Points History': 'Sejarah Mata',
          "How to Redeem Your Touch 'n Go Reload PIN?": "Cara Penebusan Touch 'n Go Reload PIN Anda:",
          "Open the Touch 'n Go eWallet Reload PIN App": "Buka Aplikasi Touch 'n Go eWallet Reload PIN",
          'Click "Reload"': 'Klik "Reload"',
          'Click "eWallet Balance"': 'Klik "Baki eWallet "',
          'Click on "TNG eWallet Reload PIN" below of the page': 'Klik "TNG eWallet Reload PIN" di bawah halaman',
          'Key in the TNG eWallet Reload PIN and click "Reload Now"': 'Masukkan TNG eWallet Reload PIN dan klik "Reload Now"',
          "Successfully redeem Touch 'n Go eWallet voucher": "Berjaya menebus baucar Touch 'n Go eWallet",
          "Only ONE (1) Reload PIN Reward can be used per reload": "Hanya SATU (1) Ganjaran Reload PIN boleh digunakan setiap kali reload",
          'To utilize the Reload Pin, log in on the microsite. At "Rewards", tap on the "View Now" button under your rewards. Then, tap on the copy icon next to the Voucher Code of the respective rewards and follow the steps provided.': 'Untuk menggunakan Reload Pin, log masuk ke mikrositus. Di "Rewards", ketuk butang "Lihat Sekarang" di bawah ganjaran anda. Kemudian, ketuk ikon salin di sebelah Voucher Code ganjaran yang bersesuaian dan ikuti langkah-langkah yang disediakan.',
          'Used Reload Pin Reward are non-reversible. Once used, the amount is not exchangeable nor refundable for credits, cash or such replacement in part or in full.': 'Ganjaran Reload Pin yang telah digunakan tidak boleh dibalikkan. Setelah digunakan, jumlah itu tidak boleh ditukar atau dikembalikan untuk kredit, wang tunai atau penggantian seumpama sepenuhnya atau sebahagian.',
          'TNG Digital will not in any way contact users for any personal information or account credentials for this Campaign.': 'TNG Digital tidak akan menghubungi pengguna untuk sebarang maklumat peribadi atau kelayakan akaun untuk Kempen ini.',
          'Reload Pin will be expired on': 'Reload Pin akan tamat tempoh pada',
          'Exclusive Discount for': 'Diskaun Eksklusif untuk',
          'Redemption rewards ONLY': 'Penebusan Ganjaran SAHAJA',
          'Your details have been successfully updated.': 'Butiran anda telah berjaya dikemaskini.',
          'It seems like the one-time PIN you entered is incorrect. Please try again with the correct code.': 'Nampaknya PIN satu kali yang anda masukkan tidak betul. Sila cuba lagi dengan kod yang betul.',
          'NOTE: This submission is entitled to 2X Points': "NOTA: Penghantaran ini berhak mendapat 2X Mata",
          'Points Spent': 'Mata Dibelanjakan',
          'Get 2X Points for First 3 Purchases of the Month': 'Dapatkan 2X Mata untuk 3 Pembelian Pertama Bulan Ini',
          'Items': 'Item',
          'Subtotal': 'Jumlah Sebahagian',
          'Please select at least one valid item to proceed.': 'Sila pilih sekurang-kurangnya satu item yang sah untuk meneruskan.',
          'Discount': 'Diskaun',
          'Are you sure you want to delete this address info?': 'Adakah anda pasti mahu memadam maklumat alamat ini?',
          'Yes': 'Ya',
          'No': 'Tidak',
          'Expires On': 'Tamat Pada',
          'Enter your name': 'Masukkan nama anda',
          'Enter your mobile number': 'Masukkan nombor telefon bimbit anda',
          'Copy': 'Salin',
          'above': 'ke atas'
        }
      },
      cn: {
        translation: {
          "Login": "登录",
          "Welcome": "欢迎",
          'Landing1': "您即将踏上赚取积分和兑换令人兴奋奖励的旅程！",
          "Next": "下面",
          'Get Started': '开始',
          'Landing2': '通过购买任何早安产品上传收据',
          'Upload Receipt': '上传收据',
          'Collect Points': '收集积分',
          'Landing3': '收集更多积分以解锁层级并兑换惊喜好康奖励！',
          'Log In': '登录',
          'LoginText': '请提供您的手机号码登录：',
          'Enter Number': '输入您的手机号码',
          'OTP Verification': 'OTP 验证',
          'Please enter the verification code': '请输入您的验证码',
          'Verify': '验证',
          'Resend Code': '重新发送验证码',
          'Resend Code in': ' 重新发送验证码',
          'Your number has been verified!': '您的号码已验证！',
          'Register': '注册',
          'Registration': '注册',
          'CompleteForm': '请填写表格以继续',
          'Full Name': '全名',
          'Date of Birth': '出生日期',
          'Email': '电子邮件',
          'I Agree': '我同意 GoodMorning Global 的条款和条件以及隐私政策。',
          'and the': '以及',
          'I Authorize': '我授权 GoodMorning Global 与选定的第三方分享我的个人数据*，以作为 G-Connect 会员提供增值服务。',
          'Submit': '提交',
          'HaveAccount': '已有帐号？',
          'Get 2X Points for First 3 Receipts': '首 3 张收据可获得双倍积分',
          'Login Here': '在此登录',
          'Success': '成功！',
          'SuccessRegister': '您的帐户已成功注册。',
          'Done': '完成',
          'Unsuccessful': '不成功',
          'AlreadyRegister': '此电子邮件已注册。请使用其他电子邮件。',
          'Home': '主页',
          'toGold': '距离黄金还有',
          'toSilver': '距离白银还很远',
          'PointShop': '积分商城',
          'ReferEarn': '推荐并赚取',
          'Badges': '徽章',
          'My Submissions': '我的提交',
          'January': '一月',
          'February': '二月',
          'March': '三月',
          'April': '四月',
          'May': '五月',
          'June': '六月',
          'July': '七月',
          'August': '八月',
          'September': '九月',
          'October': '十月',
          'November': '十一月',
          'December': '十二月',
          'Approved': '已批准',
          'Pending': "待定",
          "Rejected": "已拒绝",
          'Upload New': '上传新的',
          'TermsApply': '*适用条款和条件',
          'Popular Rewards': '热门奖励',
          'View All': '查看全部',
          'eWallet Reload Pin': '电子钱包充值代码',
          'pts': '积分',
          'Rewards': '奖励',
          'History': '历史',
          'Profile': '个人资料',
          'My Points': '我的积分',
          'My Rewards': '我的奖励',
          'All': "所有",
          'Item': "商品",
          'View Cart': '查看购物车',
          'Voucher': "优惠券",
          'ProductInfo': '产品信息',
          'Cart': '购物车',
          'Total': '总计',
          'Checkout': '前往结账',
          'Redeem Reward': '兑换奖励吗？',
          'Redeem': '兑换',
          'ConfirmRedeem': "您确定要兑换这个奖励吗？一旦详细确认完成，您的积分将被扣除。",
          "Cancel": '取消',
          'Confirm': '确认',
          'SuccessRedeem': '您已成功完成兑换。',
          'GoRewards': '前往奖励',
          exp: '截止日期:',
          'copied': '已复制到剪贴板',
          'Coming Soon': '即将推出',
          workingOn: "惊喜好康不断，请随时检查更新，",
          'Check Back': "敬请期待！",
          'Close': '关闭',
          'UploadPhoto': '上传收据照片',
          'AddedCart': '已成功添加到购物车',
          'Receipt Amount': '收据金额',
          'EnterAmount': '输入金额',
          'Receipt Date': '收据日期',
          'DD/MM/YYYY': '日/月/年',
          'Receipt Number': '收据编号',
          'EnterNumber': '输入收据编号',
          'Receipt Sample': '收据样本',
          'Sample': '实体收据样本：',
          'Store Name': '商店名称',
          'InvoiceNo': '收据/发票编号',
          'Product Name': '产品名称',
          ESample: '电子商务收据样本：',
          SuccessUpload: '您的收据已成功上传，并将在 5 个工作日内进行验证。',
          'Sure?': '您确定吗？',
          'DeleteSure': '您确定要删除此商品吗？',
          'Delete': '删除',
          'Points Earned': '积分累计',
          'Points': '积分',
          'Personal Details': '个人详情',
          'Delivery Details': '送货详情',
          'Refer a Friend': '推荐朋友',
          'Tiering': '分层',
          'Language': '语言',
          'Help': '帮助',
          'Preferences': '首选设定',
          'Logout': '推出',
          'Email Address': '电子邮件地址',
          'Phone Number': '电话号码',
          'Save': '保存',
          'DetailsUpdate': '您的信息已成功更新。',
          'EditAddress': '编辑送货地址',
          'AddAddress': '添加新地址',
          'DeliveryAddress': '输入送货地址。',
          'MobileNumber': '手机号码',
          'Name': '名字',
          'Line1': '地址行 1',
          'EnterLine1': '输入您的地址（行 1）',
          'Line2': '地址行 2',
          'EnterLine2': '输入您的地址（行 2）',
          'City': '城市',
          'ZIP / Postcode': '邮政编码',
          'Postcode': '邮政编码',
          'AddressSaved': '地址已成功保存。',
          'Tiering Benefits': '分层福利',
          'Tiering Points Required': '需要的分层积分',
          'Privileges': '福利',
          'Welcome Reward': '欢迎奖励',
          'Referral Rewards': '推荐奖励',
          'Yearly': '每年',
          'Tiering Expiring': '分层即将到期',
          'Birthday Rewards': '生日奖励',
          'FAQ': '常见问题',
          'T&C': '条款与条件',
          '16px Conditions': '条款与条件',
          'Terms and Conditions': '条款与条件',
          'Privacy Policy': '隐私政策',
          'Campaign Related': '活动相关',
          'General': '一般',
          'I would like to receive communication from GoodMorning Global about nutritional information, member benefits, latest products updates, member offers and other information. Contact GoodMorning Global for any questions. For further reading, please read': '我希望收到来自 GoodMorning Global 关于营养的资讯、最新产品更新、会员福利、优惠及其他信息的讯息。有任何问题请联系 GoodMorning Global。欲了解更多，请阅读',
          'Direct Mail': '直邮',
          'SMS': '短信',
          'Phone Call': '电话',
          'Call': '拨电',
          'I would like to receive communication on product updates, promotions & loyalty program from GoodMorning Global. You can unsubscribe at any time by updating this page.': '我希望收到 GoodMorning Global 关于产品更新、促销和忠诚计划的讯息。您可以随时通过更新此页面来取消订阅。',
          'SPEND AND WIN': '消费赢奖',
          'Prizes worth over': '总价值超过的奖品',
          'Prizes': '奖品',
          'How To Win': '如何赢取',
          'GRAND PRIZE': '大奖',
          '10 x Cash RM2,888': '10 份现金 RM2,888',
          'CONSOLATION': '安慰奖',
          'Bi-Weekly Prize': '每两周奖品',
          'Go to your shopping cart and select your purchases to "Check Out".': '到购物车并选择要购买的商品进行 "结账" 。',
          'At the Checkout page.': '在结账页面。',
          'Enter your promotion code on the Gift Card in the "Enter Voucher Code" field and tap on the "Apply" button to redeem your Gift Card.': '在 "礼卡" 栏位中输入您的优惠代码，并点击 "应用" 按钮以兑换您的礼卡。',
          'Once applied, continue to check out by tapping on the "Proceed to Checkout" button for the website or "Place Order" button to continue the payment and complete the purchase.': '应用后，点击 "结账" 以付款并完成订单。',
          'Add To Cart': '加入购物车',
          'Your Cart is Currently Empty': '您的购物车目前是空的',
          'BeforeProceed': '到结账页面之前，请确保您已将所需的一切添加到购物车中。',
          'Return to Point Shop': '返回积分商店',
          'Refer & Earn': '推荐并赚取',
          'Refer friends, get EXTRA points!': '推荐朋友，赚取额外积分',
          'Points History': '积分历史',
          "How to Redeem Your Touch 'n Go Reload PIN?": "如何兑换您的 Touch 'n Go 电子钱包充值 PIN？",
          "Open the Touch 'n Go eWallet Reload PIN App": "打开 Touch 'n Go 电子钱包充值 PIN 应用程序",
          'Click "Reload"': '点击 "充值" ',
          'Click "eWallet Balance"': '点击 "电子钱包余额"',
          'Click on "TNG eWallet Reload PIN" below of the page': '在页面下方点击 "TNG 电子钱包充值 PIN"',
          'Key in the TNG eWallet Reload PIN and click "Reload Now"': '输入 TNG 电子钱包充值 PIN 并点击 "立即充值"',
          "Successfully redeem Touch 'n Go eWallet voucher": "成功兑换 Touch 'n Go 电子钱包券",
          "Only ONE (1) Reload PIN Reward can be used per reload": "每次充值只能使用一个 (1) 充值 PIN 奖励",
          'To utilize the Reload Pin, log in on the microsite. At "Rewards", tap on the "View Now" button under your rewards. Then, tap on the copy icon next to the Voucher Code of the respective rewards and follow the steps provided.': '使用充值 PIN，请登录到微网站。 在 "奖励" 下方点击 "立即查看" 。 然后，点击奖励的 Voucher Code 旁的复制图标，然后按照提供的步骤进行操作。',
          'Used Reload Pin Reward are non-reversible. Once used, the amount is not exchangeable nor refundable for credits, cash or such replacement in part or in full.': '兑换的充值 PIN 奖励不可逆转。 一旦使用，该金额不可用于部分或全额充值、现金、替代转让或退款。',
          'TNG Digital will not in any way contact users for any personal information or account credentials for this Campaign.': 'TNG Digital 将不以任何方式联系用户以获取此活动的任何个人信息或帐户验证。',
          'Reload Pin will be expired on': 'Reload Pin 将于',
          'Exclusive Discount for': '仅限于奖励兑换',
          'Redemption rewards ONLY': '的折扣',
          'Your details have been successfully updated.': '您的信息已成功更新。',
          'It seems like the one-time PIN you entered is incorrect. Please try again with the correct code.': '您输入的一次性验证码不正确。请使用正确的验证码重试。',
          'NOTE: This submission is entitled to 2X Points': "注意：此次提交将获得双倍积分",
          'Points Spent': '已使用积分',
          'Get 2X Points for First 3 Purchases of the Month': '每月首 3 张收据可获得双倍积分',
          'Items': '项目',
          'Subtotal': '小计',
          'Please select at least one valid item to proceed.': '请选择至少一个有效项目以继续。',
          'Discount': '折扣',
          'Are you sure you want to delete this address info?': '您确定要删除此地址信息？',
          'Yes': '是',
          'No': '否',
          'Expires On': '截止日期',
          'Enter your name': '输入您的姓名',
          'Enter your mobile number': '输入您的手机号码',
          'Copy': '复制',
          'above': '以上'
        }
      }
    },
    lng: localStorage.getItem("language") && localStorage.getItem("language") === "en" ? "en" : localStorage.getItem("language") === "bm" ? "bm" : "cn"
  });

export default i18n;
